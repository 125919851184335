import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Card, Row, Col, } from 'react-bootstrap';
import LayoutHeader from '../components/layout/LayoutHeader'
import Mock from '../data/Dumy';
function Project() {
  const navigate = useNavigate();
  const DataMock = Mock.useState((state) => state.Project);

  const handleClickProject = (data: any) => {
    navigate(`/project/${data.id}`, { state: { data: data } });
  }

  useEffect(() => {
    console.log(DataMock)
  })

  return (
    <>
      <LayoutHeader />

      <div className="Content">
        <Container className="ContentContainer">
          <Row xs={1} md={4} className="g-4">
            {DataMock.map((item, index) => (
              <Col key={index}>
                <Card className='cardsHover' onClick={() => handleClickProject(item)}>
                  <Card.Img
                    variant="top"
                    src={item.thumbnail}
                    style={{ height: '200px', objectFit: 'cover', objectPosition: 'center' }}
                  />
                  <Card.Body>
                    <Card.Subtitle className="mb-2 text-muted">{item.date}</Card.Subtitle>
                    <Card.Title className='text-truncate'>{item.name}</Card.Title>
                    <Card.Text className='text-truncate'>
                      {item.description}
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
      </div>

    </>

  )
}

export default Project;
