import React from 'react';
import LayoutHeader from '../components/layout/LayoutHeader'
import { Container, Button, Form, Row, Col, Stack } from 'react-bootstrap';
import { FaTiktok, FaFacebook, FaInstagram, FaTwitter } from "react-icons/fa";

const Project: React.FC = () => {

  const openSocialMedia = (value: any) => {
    let url = "";
    switch (value) {
      case "facebook":
        url = 'https://facebook.com/awsdev.official';
        break;
      case "twitter":
        url = 'https://twitter.com/awsdev_official';
        break;
      case "tiktok":
        url = 'https://www.tiktok.com/@aws.dev';
        break;
      case "instagram":
        url = 'https://instagram.com/aws.dev';
        break;
      default:
        break;
    }

    window.open(url, '_blank');
  };

  return (
    <>
      <LayoutHeader />
      <div className="Content">
        <Container className="ContentContainer">
          <Form className='pb-2'>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Email</Form.Label>
              <Form.Control type="email" placeholder="you@email.com" />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
              <Form.Label>Message</Form.Label>
              <Form.Control placeholder='You message here...' as="textarea" rows={3} />
            </Form.Group>
            <div className="d-grid gap-2 pt-3">
              <Button variant="outline-light" size="lg">
                Send
              </Button>
            </div>
          </Form>
          <p className="text-center mt-4 mb-4">Or Social Media</p>
          <br />
          <Row>
            <Col md={3} >
              <Stack gap={1} className='text-center'>
                <div ><FaTwitter size={50} /></div>
                <div >@awsdev_official</div>
                <div className="p-2"><Button variant="outline-light" onClick={() => openSocialMedia('twitter')}>Follow</Button></div>
              </Stack>
            </Col>
            <Col md={3}>
              <Stack gap={1} className='text-center'>
                <div ><FaFacebook size={50} /></div>
                <div >@awsdev.official</div>
                <div className="p-2"><Button variant="outline-light" onClick={() => openSocialMedia('facebook')}>Follow</Button></div>
              </Stack>
            </Col>
            <Col md={3}>
              <Stack gap={1} className='text-center'>
                <div><FaInstagram size={50} /></div>
                <div>@aws.dev</div>
                <div className="p-2"><Button variant="outline-light" onClick={() => openSocialMedia('instagram')}>Follow</Button></div>
              </Stack>
            </Col>
            <Col md={3}>
              <Stack gap={1} className='text-center'>
                <div><FaTiktok size={50} /></div>
                <div>@aws.dev</div>
                <div className="p-2"><Button variant="outline-light" onClick={() => openSocialMedia('tiktok')}>Follow</Button></div>
              </Stack>
            </Col>
          </Row>


        </Container>
      </div>


    </>

  )
}

export default Project;
