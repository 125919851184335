import React, { useEffect, useState } from 'react';
import { Card, Row, Col } from 'react-bootstrap';

interface components {
  data: any;
}

const PostPmo: React.FC<components> = ({ data }) => {
  const [ImageProduct, setImageProduct] = useState();


  const openGooglePlay = (url: any) => {
    window.open(url, '_blank');
  };

  useEffect(() => {
    setImageProduct(data.thumbnail)
  }, [data.thumbnail])

  return (
    <>
      <div className='p-4 text-center'>
        <Card.Img
          variant="top"
          src={ImageProduct}
          style={{ width: '1024px', height: '500px', objectFit: 'cover', objectPosition: 'center' }}
        />
      </div>

      <p style={{ textAlign: "justify" }}>The ultimate shopping expense tracking application designed to effortlessly monitor your spending while adding a touch of convenience. With Cabe, recording and managing your shopping expenses has never been easier or more practical, making it the perfect companion for your financial journey.</p>

      <p style={{ textAlign: "justify" }}>Discover the Remarkable Features of Cabe:</p>

      <ul>
        <li style={{ textAlign: "justify" }}>Seamlessly capture your daily, weekly, monthly, or even yearly expenditures with a simple tap.</li>
        <li style={{ textAlign: "justify" }}>Elevate your record-keeping game by securely storing your shopping receipts as photos, ensuring your financial documentation is always at your fingertips.</li>
        <li style={{ textAlign: "justify" }}>Dive into your shopping history&mdash;a comprehensive overview of your spending habits that empowers you with insights into your overall expenses.</li>
      </ul>

      <p style={{ textAlign: "justify" }}>Cabe caters to those who are committed to mastering their shopping expenditures with efficiency and ease. Don&#39;t miss out on the opportunity to regain control of your finances in a whole new way.</p>

      <p style={{ textAlign: "justify" }}>Ready to embark on a smarter shopping expense adventure? Take the leap and experience Cabe&mdash;your ultimate shopping expense sidekick. Download the Cabe app now, and let&#39;s begin this journey of financial empowerment together!</p>

      <Row>
        <Col md={2}>
          <Card.Img
            variant="top"
            src="https://play.google.com/intl/id/badges/static/images/badges/id_badge_web_generic.png"
            style={{ width: '100%', height: 'auto', objectFit: 'cover', objectPosition: 'center' }}
            onClick={()=>openGooglePlay("https://play.google.com/store/apps/details?id=com.awsdev.cabe&pcampaignid=web_share")}
          />
        </Col>
      </Row>

    </>
  )
}

export default PostPmo;
