import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Container, Card } from 'react-bootstrap';
import LayoutHeader from '../../components/layout/LayoutHeader'
// import Mock from '../../data/Dumy';
import Post1 from '../../data/post/PostPmo';
import Post2 from '../../data/post/PostCabe';
const Detail = () => {
  const Location = useLocation();
  const locationState = Location.state.data;

  const handlePage = () => {
    const id: any = locationState.id;
    // eslint-disable-next-line default-case
    switch (parseInt(id)) {
      case 1:
        return <Post1 data={locationState} />;
      case 2:
        return <Post2 data={locationState} />;
    }
  }

  useEffect(() => {
    console.log(locationState)

  })

  return (
    <>
      <LayoutHeader />
      <div className="Content">
        <Container className="ContentContainer">
          <div>
            <Card.Title>{locationState.name}</Card.Title>
            <Card.Subtitle className="mb-2 text-muted">{locationState.date}</Card.Subtitle>
          </div>
          <div className='pt-2'>
            {handlePage()}
          </div>
        </Container>
      </div>
    </>
  )
}

export default Detail;
