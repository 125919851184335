import React, { useEffect, useState } from 'react';
import { Card} from 'react-bootstrap';

interface components {
  data: any;
}

const PostPmo: React.FC<components> = ({ data }) => {
  const [ImageProduct, setImageProduct] = useState();

  useEffect(() => {
    setImageProduct(data.thumbnail)
  }, [data.thumbnail])

  return (
    <>
      <div className='p-4 text-center'>
        <Card.Img
          variant="top"
          src={ImageProduct}
          style={{ width: '512px', height: '512px', objectFit: 'cover', objectPosition: 'center' }}
        />
      </div>

      <p style={{ textAlign: "justify" }}>In this challenging digital era, behold PMO - Offline Password Manager, your trusted companion in safeguarding your precious passwords. PMO acts as a bridge to limitless digital security, presenting an elegant and affordable solution to exclusively protect your digital identity on your Android device.</p>

      <p style={{ textAlign: "justify" }}>Now, you can cast aside worries of unauthorized access or data theft, as PMO keeps all your secrets tightly embraced within the confines of your personal device. Its captivating and intuitive interface invites you to navigate the realm of passwords effortlessly, without the need for a vulnerable internet connection.</p>

      <p style={{ textAlign: "justify" }}>But that&#39;s not all; advanced security has been ingrained with a dual-layered protection system. First, the intelligence of a PIN, serving as the initial defense that thwarts prying eyes from glimpsing your passwords. And for unmatched convenience, employ access through your very own fingerprint &ndash; with just a gentle touch, the world of passwords unfolds.</p>

      <p style={{ textAlign: "justify" }}>Make PMO your steadfast companion that always safeguards your digital secrets, without sacrificing ease and aesthetics. From password storage to management, everything is at your fingertips, ready to answer your call at any given moment. Choose secure digital intelligence, choose PMO - Offline Password Manager, because security is the key to confidently navigate the online world.</p>

      <p style={{ textAlign: "justify" }}><br />
        PMO - Password Manager Offline is a comprehensive Android application that excels in security, user interface, and efficient password management. Let&#39;s delve into its standout features from these perspectives:</p>

      <p style={{ textAlign: "justify" }}><strong>Security:</strong></p>

      <p style={{ textAlign: "justify" }}>PMO places paramount importance on security, ensuring that your sensitive data remains confidential and shielded from potential threats. It offers a two-tier security approach:</p>

      <ul>
        <li style={{ textAlign: "justify" }}><strong>PIN Protection:</strong> The app provides an initial layer of defense through a Personal Identification Number (PIN). This PIN acts as a gatekeeper, ensuring that only authorized users can access the password vault. This feature guards against unauthorized access and secures your data from prying eyes.</li>
        <li style={{ textAlign: "justify" }}><strong>Fingerprint Authentication:</strong> PMO leverages the biometric capabilities of your Android device, allowing you to unlock the app using your fingerprint. This advanced authentication method adds an extra layer of security, making it convenient for you to access your passwords while ensuring that no one else can breach your defenses.</li>
      </ul>

      <p style={{ textAlign: "justify" }}>&nbsp;</p>

      <p style={{ textAlign: "justify" }}><strong>User Interface:</strong></p>

      <p style={{ textAlign: "justify" }}>PMO offers an inviting and user-friendly interface that streamlines your password management experience. Its design principles include:</p>

      <ul>
        <li style={{ textAlign: "justify" }}><strong>Intuitive Navigation:</strong> The app boasts a well-organized and easy-to-navigate interface. This ensures that you can effortlessly find and manage your passwords without any confusion or frustration.</li>
        <li style={{ textAlign: "justify" }}><strong>Aesthetically Pleasing Design:</strong> PMO employs a visually appealing design, making your interactions with the app enjoyable. The sleek and modern layout enhances your user experience, making the process of handling your passwords a delight.</li>
      </ul>

      <p style={{ textAlign: "justify" }}>&nbsp;</p>

      <p style={{ textAlign: "justify" }}><strong>Efficient Password Management:</strong></p>

      <p style={{ textAlign: "justify" }}>PMO is engineered to enhance the efficiency of managing your passwords, ensuring that you can maintain control over your digital identities with ease:</p>

      <ul>
        <li style={{ textAlign: "justify" }}><strong>Offline Capability:</strong> The standout feature of PMO is its offline functionality. It operates entirely without an internet connection, ensuring that your passwords are stored locally on your Android device. This eliminates the risk of online breaches, providing an extra layer of protection.</li>
        <li style={{ textAlign: "justify" }}><strong>Comprehensive Storage:</strong> PMO serves as a secure vault where you can store a wide range of passwords, from website logins to credit card information. This consolidation of passwords simplifies your digital life, preventing the need to remember multiple credentials.</li>
        <li style={{ textAlign: "justify" }}><strong>Quick Access:</strong> With fingerprint authentication and efficient navigation, PMO enables you to access your passwords swiftly. This feature is especially helpful when you need to retrieve a password promptly.</li>
      </ul>

      <p style={{ textAlign: "justify" }}>&nbsp;</p>

      <p style={{ textAlign: "justify" }}>In summary, PMO - Password Manager Offline is a cutting-edge Android application that excels in security, user interface design, and efficient password management. Its robust security measures, visually appealing interface, and streamlined password management capabilities ensure that your digital assets are protected, easily accessible, and efficiently organized.</p>

    </>
  )
}

export default PostPmo;
