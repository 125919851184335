import React from 'react';
import { Container } from 'react-bootstrap';

const LayOutFooter = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="bg-dark text-light fixed-bottom">
      <Container className='p-4'>
        <p className="text-center mb-0 glow-text">AwsDev © {currentYear}</p>
      </Container>
    </footer>
  )
}

export default LayOutFooter;
