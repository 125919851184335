import React, { useEffect, useState } from 'react';
import { Container, Navbar, Nav } from 'react-bootstrap';
import { FiArrowLeft } from "react-icons/fi";
import { useLocation, useNavigate } from 'react-router-dom';



function LayoutHeader() {
  const location = useLocation();
  const id = location.pathname.split('/').pop();
  const navigate = useNavigate();
  const [PageNow, setPageNow] = useState("");

  const handleBack = () => {
    if (PageNow === `/project/${id}`) {
      navigate(-1)
    } else {
      navigate('/', { replace: true });
    }
  }

  useEffect(() => {
    setPageNow(window.location.pathname);
  }, [])

  return (
    <>
      <Navbar bg="dark" variant="dark" expand="lg" className='fixed-top'>

        <Container className='p-4'>

          <Navbar.Brand onClick={handleBack}>
            <FiArrowLeft />
          </Navbar.Brand>

          <Navbar.Toggle aria-controls="responsive-navbar-nav " />

          <Navbar.Collapse id="responsive-navbar-nav ">
            <Nav className="justify-content-end">
              <Nav.Link href="/project" className={PageNow === '/project' || PageNow === `/project/${id}` ? 'active' : ''}>Project</Nav.Link>
              <Nav.Link href="/contact" className={PageNow === '/contact' ? 'active' : ''}>Contact</Nav.Link>
            </Nav>
          </Navbar.Collapse>
          {/* <Breadcrumb className="mt-3">
            <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
            {PageNow === '/project' && <Breadcrumb.Item active>Project</Breadcrumb.Item>}
            {PageNow === `/project/${id}` && (
              <>
                <Breadcrumb.Item href="/project">Project</Breadcrumb.Item>
                <Breadcrumb.Item active>{id}</Breadcrumb.Item>
              </>
            )}
            {PageNow === '/contact' && <Breadcrumb.Item active>Contact</Breadcrumb.Item>}
          </Breadcrumb> */}
        </Container>

      </Navbar>
    </>
  );
}

export default LayoutHeader;