import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

// import Footer from './components/Footer';
import Home from './pages/Home';
import About from './pages/About';
import Project from './pages/Project';
import Contact from './pages/Contact';
import Detail from './pages/project/Detail';
import './App.css';
import LayOutFooter from './components/layout/LayoutFooter';

const App = () => {
  return (
    <Router>
      <Container>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/about" element={<About />} />
          <Route exact path="/project" element={<Project />} />
          <Route exact path="/project/:id" element={<Detail />} />
          <Route exact path="/contact" element={<Contact />} />
        </Routes>
        <LayOutFooter/>
      </Container>
    </Router>
  );
}

export default App;
