import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Stack, Container, Row, Col, Image } from 'react-bootstrap';
import Logo from "../assets/logo/logo-no-background.png"
import 'animate.css';


function Home() {
  const navigate = useNavigate();

  const [AnimationSet, setAnimation] = useState("");

  const handleProjectHover = () => {
    // setAnimation("animate__flash")
    // setTimeout(() => {
    //   setAnimation("")
    // }, 500);
  };

  useEffect(() => {
    setAnimation("animate__fadeIn animate__slow")
    setTimeout(() => {
      setAnimation("")
    }, 1500);

  }, []);

  return (
    <Container className="vertical-center">
      <div className="center-content text-center">
        <Row>
          <Col className='p-4' md={12}>
            <div className="d-flex justify-content-center align-items-center" >
              <Stack direction="horizontal" gap={3}>
                <div className="p-2">
                  <div onClick={()=>navigate('/project')} className='glow-on-hover' onMouseEnter={handleProjectHover}>Project</div>
                </div>
                <div className="p-2">
                  <div className='glow-on-hover'>*</div>
                </div>
                <div className="p-2">
                  <div onClick={()=>navigate('/contact')} className='glow-on-hover' onMouseEnter={handleProjectHover}>Contact</div>
                </div>
              </Stack>
            </div>
          </Col>

          <Col className='p-4' md={12}>
            <div className={`animate__animated ${AnimationSet}`}>
              <Image
                className="glow-logo"
                style={{ maxWidth: '50%', maxheight: 'auto' }}
                src={Logo}
                rounded />
            </div>
          </Col>
          <Col className='p-4' md={12}>
            <p className="glow-text" style={{fontSize:12}}>Hi, AwsDev Here, I'm Full Stack Developer.</p>
          </Col>
        </Row>
      </div>
    </Container>
  )
}

export default Home;
