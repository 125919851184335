import { Store } from "pullstate";
import Cabe from "./post/image/cabe.png"
const Mock = new Store({

    Project: [
        // {
        //     id: 1,
        //     name: "PMO - Password Manager Offline",
        //     thumbnail: "https://miro.medium.com/v2/resize:fit:916/1*z2BaY_MTgmcjgLfNyGQJ4g.png",
        //     description: "",
        //     image: [
        //         {
        //             id: 1,
        //             image: "https://miro.medium.com/v2/resize:fit:916/1*z2BaY_MTgmcjgLfNyGQJ4g.png",
        //         },
        //         {
        //             id: 2,
        //             image: "https://miro.medium.com/v2/resize:fit:916/1*z2BaY_MTgmcjgLfNyGQJ4g.png",
        //         }
        //     ],
        //     date: '2017-12-25'
        // },
        {
            id: 2,
            name: "CABE - Catatan Belanja",
            thumbnail: Cabe,
            description: "",
            image: [
                {
                    id: 1,
                    image: "https://miro.medium.com/v2/resize:fit:916/1*z2BaY_MTgmcjgLfNyGQJ4g.png",
                },
                {
                    id: 2,
                    image: "https://miro.medium.com/v2/resize:fit:916/1*z2BaY_MTgmcjgLfNyGQJ4g.png",
                }
            ],
            date: '2017-12-25'
        },
        // {
        //     id: 3,
        //     name: "Yok Bisa Yok",
        //     thumbnail: "https://cdn.dribbble.com/userupload/2774366/file/original-913cabba9fce49241a562b628961b9aa.jpg",
        //     description: "",
        //     image: [
        //         {
        //             id: 1,
        //             image: "https://miro.medium.com/v2/resize:fit:916/1*z2BaY_MTgmcjgLfNyGQJ4g.png",
        //         },
        //         {
        //             id: 2,
        //             image: "https://miro.medium.com/v2/resize:fit:916/1*z2BaY_MTgmcjgLfNyGQJ4g.png",
        //         }
        //     ],
        //     date: '2017-12-25'
        // },
        // {
        //     id: 4,
        //     name: "Mang Galon",
        //     thumbnail: "https://assets.justinmind.com/wp-content/uploads/2019/08/mobile-banking-app-design-ui-patterns-challenger-apps-1.png",
        //     description: "",
        //     image: [
        //         {
        //             id: 1,
        //             image: "https://miro.medium.com/v2/resize:fit:916/1*z2BaY_MTgmcjgLfNyGQJ4g.png",
        //         },
        //         {
        //             id: 2,
        //             image: "https://miro.medium.com/v2/resize:fit:916/1*z2BaY_MTgmcjgLfNyGQJ4g.png",
        //         }
        //     ],
        //     date: '2017-12-25'
        // },
        // {
        //     id: 5,
        //     name: "In Property",
        //     thumbnail: "https://i.pinimg.com/originals/11/5c/1d/115c1d00faa86ed2c63be45525bb457e.png",
        //     description: "",
        //     image: [
        //         {
        //             id: 1,
        //             image: "https://miro.medium.com/v2/resize:fit:916/1*z2BaY_MTgmcjgLfNyGQJ4g.png",
        //         },
        //         {
        //             id: 2,
        //             image: "https://miro.medium.com/v2/resize:fit:916/1*z2BaY_MTgmcjgLfNyGQJ4g.png",
        //         }
        //     ],
        //     date: '2017-12-25'
        // },
    ]
});

export default Mock;